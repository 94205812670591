<template>
  <div class="d-flex flex-column">
    <jumbotron src="/img/backgrounds/destinations-background.jpg">
      <v-container fluid class="fill-height white--text">
        <v-row class="fill-height" no-gutters align="end">
          <v-col>
            <h1
              :class="{
                'display-2': isOnPC,
                'display-1': !isOnPC,
                'px-4': isOnPC,
                'px-2': !isOnPC
              }"
              style="text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.7)"
            >Destinations</h1>
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <v-container fluid class="px-0">
      <v-row>
        <v-col cols="4" v-for="(category, x) in destinationCategories" :key="`${x}${JSON.stringify(category)}`">
          <v-card :to="category.link">
            <v-img :src="category.image" class="white--text align-end">
              <div class="lightbox d-block">{{ destination.name }}</div>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="fill-height">
      <v-row dense>
        <v-col cols="12">
          <p style="text-align: justify" >
            <!-- Blurb about *all* destinations here-->
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters :key="JSON.stringify($route.query)">
        <v-col cols="12">
          <v-container class="fill-height" >
            <v-row dense>
              <v-col
                cols="12"
                sm="6"
                md="4"
                xl="1"
                v-for="(destination, n) in destinations"
                :key="`${n}${JSON.stringify(destination)}`"
              >
                <destination-card :destination="destination" />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row justify="center" v-intersect="populateList">
        <v-progress-circular v-show="loading" indeterminate />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import jumbotron from '../components/Jumbotron'
import firebase from '../plugins/firebase'
import DestinationCard from '../components/DestinationCard'

export default {
  components: {
    jumbotron,
    'destination-card': DestinationCard
  },

  computed: {
    destinationCategories () {
      return this.$store.destinationCategories
    },
    queryPage () {
      return this.$route.query.page
    },
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  data () {
    return ({
      finalHit: false,
      destinations: [],
      lastDestinationDocRef: null,
      loading: false
    })
  },

  methods: {
    getDestinations (page, limit = 6) {
      const db = firebase.firestore()

      return new Promise((resolve, reject) => {
        var query = db.collection('destinations/').orderBy('rating', 'desc').orderBy('name').limit(limit)
        if (this.$data.lastDestinationDocRef) query = query.startAfter(this.$data.lastDestinationDocRef)
        query.get().then(querySnapshot => {
          var o = {}

          o.results = querySnapshot.docs.map(doc => {
            return Object.assign({ id: doc.id }, doc.data())
          })

          o.lastDocReference = querySnapshot.docs[querySnapshot.docs.length - 1]

          resolve(o)
        }).catch(e => { reject(e) })
      })
    },

    async populateList () {
      // Check if already fetched the last in the list before fetching
      if (!this.$data.finalHit && !this.$data.loading) {
        this.$data.loading = true

        const fetchedDestinations = await this.getDestinations()

        let existingDestinations = this.$data.destinations
        existingDestinations = existingDestinations.concat(fetchedDestinations.results)
        this.$data.destinations = existingDestinations

        this.$data.lastDestinationDocRef = fetchedDestinations.lastDocReference
        this.$data.loading = false

        // If results are empty set finalHit to true
        if (!fetchedDestinations.results.length) this.$data.finalHit = true
      }
    }
  },

  beforeCreate () {
    document.title = 'Destinations - AmborJo'
  },

  watch: {
    destination: function (v) {
      document.title = `${v.title ? v.title : v.name} - AmborJo`
    }
  }
}
</script>
