<template>
  <v-card width="100%"
    :to="`/destinations/${destination.id}/${destination.url ? destination.url : ''}`"
    :title="destination.title ? destination.title : destination.name"
  >
    <v-img
      width="100%"
      class="white--text align-end"
      :aspect-ratio="1.6"
      :src="destination.thumbnailURL"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-row>
      </template>
      <div class="lightbox">
        <v-card-title class="d-block text-truncate">{{ destination.title ? destination.title : destination.name }}</v-card-title>
      </div>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'DestinationCard',
  props: {
    destination: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.lightbox {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}
</style>
